<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="channel-main">

            <!-- Channel Sidebar -->
            <div class="channel-left">
                <div class="card">
                    <div class="body">
                        <!-- Business Model -->
                        <div class="add-business mb-3">
                            <!-- Add Business Channel Button-->
                            <b-button variant="primary w-100" size="lg" v-b-modal.add_channel>Add Business Channel</b-button>

                            <!-- Add Business Channel Business -->
                            <b-modal id="add_channel" title="Add Business Channel" hide-footer>
                                <ul class="list-unstyled mb-0 payment-single-transfer">
                                    <ValidationObserver v-slot="{ passes }">
                                        <form @submit.prevent="passes(channelSubmitForm)" id="channel-form">
                                            <li>
                                                <ValidationProvider name="businessName" rules="required" v-slot="{ errors}">
                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':singleBusinessDatas.businessName && singleBusinessDatas.businessName.length != 0  && !errors[0]}">
                                                        <span class="float-label">
                                                            <label for="functions">Select Business*</label>
                                                            <multiselect
                                                                placeholder=""
                                                                v-model="singleBusinessDatas.businessName"  
                                                                :show-labels="false" 
                                                                :options="businessNameOptions" 
                                                                :searchable="false">
                                                            </multiselect> 
                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                            <i class="fa fa-check" v-if="singleBusinessDatas.businessName && singleBusinessDatas.businessName.length != 0 && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <div class="d-flex align-items-center justify-content-between mb-3">
                                                    <p class="mb-0 mr-3 custom-switch-text">Do you require technical Integration?</p>
                                                    <div class="custom-switch success-handle">
                                                        <input type="checkbox" id="display_technical_integration" class="custom-switch-input" v-model="singleBusinessDatas.technicalIntegration">
                                                        <label for="display_technical_integration" class="custom-switch-label">
                                                            <span class="custom-switch-text1">Yes</span><span class="custom-switch-text2">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <ValidationProvider name="channelName" :rules="!singleBusinessDatas.channelURL ? 'required' : ''" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.channelName || errors[0],'success':singleBusinessDatas.channelName && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="singleBusinessDatas.channelName" class="form-control" name="channelName" id="channelName" placeholder="Trading Name*">
                                                            <label for="channelName" class="control-label">Trading Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.channelName || errors[0] "></i>
                                                            <i class="fa fa-check" v-if="singleBusinessDatas.channelName && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li v-if="singleBusinessDatas.technicalIntegration">
                                                <div class="phone-with-country d-flex justify-content-between align-items-center">
                                                    <div class="select-country small border-grey">
                                                        <div class="form-group form-select single-multiselect">
                                                            <span class="float-label">
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="channels.currentURL"   
                                                                    :show-labels="false" 
                                                                    :options="channelURL_option"
                                                                    :searchable="false">
                                                                </multiselect> 
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="add-number">
                                                        <ValidationProvider name="channelURL" :rules="!singleBusinessDatas.channelName ? 'required' : ''" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.channelURL || errors[0],'success':singleBusinessDatas.channelURL && !errors[0]}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="singleBusinessDatas.channelURL" class="form-control" name="channelURL" id="channelURL" placeholder="Channel URL*">
                                                                    <label for="channelURL" class="control-label">Channel URL*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.channelURL || errors[0] "></i>
                                                                    <i class="fa fa-check" v-if="singleBusinessDatas.channelURL && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </li>
                                            <li v-if="singleBusinessDatas.technicalIntegration">
                                                <ValidationProvider name="channelIP" :rules="!singleBusinessDatas.channelIP ? 'required' : ''" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !singleBusinessDatas.channelIP || errors[0],'success':singleBusinessDatas.channelIP && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="singleBusinessDatas.channelIP" class="form-control" name="channelIP" id="channelIP" placeholder="Channel IP Address*">
                                                            <label for="channelIP" class="control-label">Channel IP Address*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !singleBusinessDatas.channelIP || errors[0] "></i>
                                                            <i class="fa fa-check" v-if="singleBusinessDatas.channelIP && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <b-button size="lg" @click="$bvModal.hide('add_channel')">Cancel</b-button>
                                            <b-button type="submit" variant="primary ml-1" size="lg">Add Channel</b-button>
                                        </form>
                                    </ValidationObserver>
                                </ul>
                            </b-modal>
                        </div>

                        <!-- Business List -->
                        <div class="collapse-business">
                            <div class="business-list" v-for="(busines,index) in businessDatas" :key="index">
                                <!-- Business Collapse Button -->
                                <b-button size="lg" v-b-toggle="'business_'+index">
                                    <span>{{ busines.businessName }}</span>
                                    <strong class="collapse-icon ml-auto"><i class="fa fa-chevron-up"></i></strong>
                                </b-button>

                                <!-- Business Collapse Inner Area -->
                                <b-collapse class="" :id="'business_'+index" accordion="my-accordion" role="tabpanel" :visible="busines.isActive">
                                    <div class="show-area">
                                        <!-- Trade List -->
                                        <ul class="list-unstyled trade-list mb-0">
                                            <li v-for="(tname,index) in busines.tradeLists" :key="index" :class="{'active' : tname.isActive}">
                                                <label>{{ tname.name }}
                                                    <br/><small class="text-monospace">{{ tname.url }}</small>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </b-collapse> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Channel Right Body -->
            <div class="channel-right">
                <!-- API keys -->
                <div class="card">
                    <div class="header border-bottom">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="header-left">
                                <h5 class="text-primary font-weight-normal">PayAngel US</h5>
                                <span><i class="fa fa-calendar mr-1"></i> 05 Jan 2021 - 11:06:18</span>
                            </div>
                            <div class="header-right">
                                <div class="custom-switch xlg success-error-bg">
                                    <input type="checkbox" id="test_live" class="custom-switch-input">
                                    <label class="custom-switch-label" for="test_live">
                                        <span class="custom-switch-text1">Live</span>
                                        <span class="custom-switch-text2">Test</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body">
                        <ul class="list-unstyled mb-0 key-list">
                            <li>
                                <h6 class="mb-1">API keys</h6>
                                <p><small>Authenticate to our StandApp API by including your API key in the request. You can manage your API keys from this page. Your API keys carry many privileges;be sure to keep them secret.</small></p>
                            </li>
                            <li>
                                <div class="d-flex justify-content-start mb-3">
                                    <div class="left">
                                        <h6 class="mb-1">Secret Key</h6>
                                        <p class="mb-0"><small>Dont't share this key with anyone</small></p>
                                    </div>
                                    <div class="right flex-fill">
                                        <div class="input-group mb-0">
                                            <span class="form-control key">{{APIkeysInfo.secretKey}}</span>
                                            <div class="input-group-append">
                                                <b-button variant="outline-primary" @click="copyKey('secretKey')">Copy</b-button>
                                                <b-button variant="outline-primary">Regenerate</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-start mb-3">
                                    <div class="left">
                                        <h6 class="mb-1">Public Key</h6>
                                        <p class="mb-0"><small>Use this key on your website</small></p>
                                    </div>
                                    <div class="right flex-fill">
                                        <div class="input-group mb-0">
                                            <span class="form-control regenerate-key">{{APIkeysInfo.publickKey}}</span>
                                            <div class="input-group-append">
                                                <b-button variant="outline-primary" @click="copyKey('publickKey')">Copy</b-button>
                                                <b-button variant="outline-primary">Regenerate</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-start mb-3">
                                    <div class="left">
                                        <h6 class="mb-1">Last updated</h6>
                                        <p class="mb-0"><small>The date and time an API Key was last updated</small></p>
                                    </div>
                                    <div class="right">
                                        <div class="input-group mb-0">
                                            <span class="form-control">05 Jan 2021 - 11:06:18</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- URLs -->
                <div class="card">
                    <div class="header border-bottom">
                        <h5 class="text-primary font-weight-normal mb-0">URLs</h5>
                    </div>
                    <div class="body">
                        <ValidationObserver v-slot="{ passes }">
                            <form id="url-form" @submit.prevent="passes(urlFormSubmit)">
                                <ul class="list-unstyled mb-0 key-list">
                                    <li>
                                        <div class="d-flex justify-content-start">
                                            <div class="left">
                                                <h6 class="mb-1">Channel URL</h6>
                                                <p class="mb-0"><small>The main URL of the channel</small></p>
                                            </div>
                                            <div class="right flex-fill">
                                                <ValidationProvider name="url" :rules="{ required: true, regex:/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/ }" v-slot="{ errors }">
                                                    <div class="form-group" :class="{'errors': errors[0],'success':urlFormData.urlValue && !errors[0]}">
                                                        <span class="float-label">
                                                            <b-input-group>
                                                                <template #prepend>
                                                                    <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                        <template #button-content>
                                                                            {{ urlFormData.urlPrefixsTitle }}
                                                                        </template>
                                                                        <b-dropdown-item v-for="(prefix,index) in urlSelectList" :key="index" @click="urlSelectedOptions(index,'url')" :active="index == urlFormData.urlActiveIndex">
                                                                            {{ prefix.name }}
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>
                                                                </template>
                                                                <b-form-input v-model="urlFormData.urlValue"></b-form-input>
                                                            </b-input-group>
                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                            <i class="fa fa-check" v-if="urlFormData.urlValue && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex justify-content-start">
                                            <div class="left">
                                                <h6 class="mb-1">Channel IP Address</h6>
                                                <p class="mb-0"><small>The hosting server IP Address</small></p>
                                            </div>
                                            <div class="right flex-fill">
                                                <ValidationProvider name="ipaddress" :rules="{ required: true, regex:/^((\d){1,3}\.){3}(\d){1,3}$/ }" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && errors[0],'success':urlFormData.ipAddress && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="urlFormData.ipAddress" class="form-control" id="ipaddress" placeholder="Campaign City">
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                            <i class="fa fa-check" v-if="urlFormData.ipAddress && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex justify-content-start">
                                            <div class="left">
                                                <h6 class="mb-1">Payment Success URL</h6>
                                                <p class="mb-0"><small>The URL displayed after a successful payment</small></p>
                                            </div>
                                            <div class="right flex-fill">
                                                 <div class="form-group">
                                                    <span class="float-label">
                                                        <b-input-group>
                                                            <template #prepend>
                                                                <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                    <template #button-content>
                                                                        {{ urlFormData.successUrlPrefixsTitle }}
                                                                    </template>
                                                                    <b-dropdown-item v-for="(prefix,index) in urlSelectList" :key="index" @click="urlSelectedOptions(index,'successUrl')" :active="index == urlFormData.successUrlActiveIndex">
                                                                        {{ prefix.name }}
                                                                    </b-dropdown-item>
                                                                </b-dropdown>
                                                            </template>
                                                            <b-form-input v-model="urlFormData.successURL"></b-form-input>
                                                        </b-input-group>
                                                    </span>
                                                 </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex justify-content-start">
                                            <div class="left">
                                                <h6 class="mb-1">Payment Error URL</h6>
                                                <p class="mb-0"><small>The URL displayed after a failed payment</small></p>
                                            </div>
                                            <div class="right flex-fill">
                                                <b-input-group>
                                                    <template #prepend>
                                                        <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                            <template #button-content>
                                                                {{ urlFormData.errorUrlPrefixsTitle }}
                                                            </template>
                                                            <b-dropdown-item v-for="(prefix,index) in urlSelectList" :key="index" @click="urlSelectedOptions(index,'error')" :active="index == urlFormData.errorUrlActiveIndex">
                                                                {{ prefix.name }}
                                                            </b-dropdown-item>
                                                        </b-dropdown>
                                                    </template>
                                                    <b-form-input v-model="urlFormData.errorURL"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                            <b-button size="lg" class="btn-mw">Cancel</b-button>
                                            <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Save changes</b-button>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>

                <!-- Webhook -->
                <div class="card">
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Webhooks</h2>
                        <!-- Webhook Button -->
                        <b-button variant="primary" size="lg" v-b-modal.add_webhooks class="d-flex justify-content-between align-items-center">New Webhook <i class="fa ml-2"><img src="@/assets/webhook-w.svg" width="18" alt=""></i></b-button>
                        
                        <!-- Webhook Modal -->
                        <b-modal id="add_webhooks" title="Add webhook" hide-footer>
                            <div class="modal-area">
                                <ul class="list-unstyled mb-0 payment-single-transfer">
                                    <ValidationObserver v-slot="{ passes }">
                                        <form  id="add_webhook" @submit.prevent="passes(webHooksForm)">
                                            <li>   
                                                <ValidationProvider name="url" rules="required" v-slot="{ errors}">
                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !webhooks.url,'success':webhooks.url}">
                                                        <span class="float-label">
                                                            <label for="functions">Channel URL*</label>
                                                            <multiselect
                                                                placeholder=""
                                                                v-model="webhooks.url"  
                                                                :show-labels="false" 
                                                                :options="urlOptions" 
                                                                :searchable="false"
                                                                @select="onSelectURL">
                                                            </multiselect> 
                                                            <i class="fa fa-times" v-if="errors.length && !webhooks.url"></i>
                                                            <i class="fa fa-check" v-if="webhooks.url"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>   
                                                <div class="form-group">
                                                    <!-- intial input -->
                                                    <span class="float-label" v-if="!webhooks.extension">
                                                        <input type="text" class="form-control" name="extension" id="extension" placeholder="Webhook Extension">
                                                        <label for="extension" class="control-label">Webhook Extension</label>
                                                        <div class="line"></div>
                                                    </span>
                                                    <!-- after select Channel URL input -->
                                                    <b-input-group :prepend="webhooks.extension+'/'" class="mb-2" v-if="webhooks.extension">
                                                        <b-form-input aria-label="First name"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </li>
                                            <li>
                                                <ValidationProvider name="events" rules="required" v-slot="{ errors}">
                                                    <div class="country-dropdown curancy-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':webhooks.events.length != 0  && !errors[0]}">
                                                            <span class="float-label">
                                                                <label for="roles">Events*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="webhooks.events" 
                                                                    :show-labels="false" 
                                                                    :options="events_options" 
                                                                    :searchable="false"
                                                                    :multiple="true" 
                                                                    :close-on-select="true"
                                                                    :clear-on-select="false" 
                                                                    label="name" 
                                                                    track-by="name"  
                                                                    :limit="0"
                                                                    :limit-text="displayText"
                                                                    @select="isCheckedInput"
                                                                    @remove="isCheckedInput">
                                                                    <template slot="beforeList">
                                                                        <span class="multiselect__option" @click="selectAllChanged">
                                                                            <div class="option__desc">
                                                                                <span class="option__title">Select all</span>
                                                                            </div>
                                                                            <div class="multiselect__element multiselect-all multi-drop">
                                                                                <div class="control-inline fancy-checkbox" for="isChecked">
                                                                                    <input type="checkbox" id="checkbox2" name="checkbox2" :checked="webhooks.isAllChecked">
                                                                                    <span for="checkbox2"></span>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </template>
                                                                    <template slot="option" slot-scope="props">
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}</span>
                                                                        </div>
                                                                        <div class="multiselect__element multiselect-all multi-drop">
                                                                            <div class="control-inline fancy-checkbox" for="isChecked">
                                                                                <input type="checkbox" id="isChecked" name="isChecked" :checked="props.option.isChecked == true">
                                                                                <span for="isChecked"></span>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="webhooks.events.length != 0 && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <ValidationProvider name="status" rules="required" v-slot="{ errors}">
                                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !webhooks.status,'success':webhooks.status}">
                                                        <span class="float-label">
                                                            <label for="functions">Select status*</label>
                                                            <multiselect
                                                                placeholder=""
                                                                v-model="webhooks.status"  
                                                                :show-labels="false" 
                                                                :options="statusOptions" 
                                                                :searchable="false">
                                                            </multiselect> 
                                                            <i class="fa fa-times" v-if="errors.length && !webhooks.status"></i>
                                                            <i class="fa fa-check" v-if="webhooks.status"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </li>
                                            <li>
                                                <div class="d-flex flex-column justify-content-between align-items-center w-100 mt-3">
                                                    <b-button type="submit" variant="primary btn-mw" size="lg">Add Webhook</b-button>  
                                                    <b-button @click="$bvModal.hide('add_webhooks')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>           
                                                </div>
                                            </li>
                                        </form>
                                    </ValidationObserver>
                                </ul>
                            </div>
                        </b-modal>
                    </div>

                    <div class="body">

                        <!-- Webhook Table -->
                        <b-table 
                            responsive
                            hover 
                            outlined
                            table-class="js-basic-example table-custom mb-0"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(url)="data">
                                <a href="javascript:void(0);">{{ data.value }}</a>
                            </template>
                            <template #cell(dropdawn)="data">
                                <select v-model="data.value.selected" size="sm" class="btn btn-sm btn-filter" :class="data.value.class" @change="activeStatus($event,(currentPage-1) * perPage + data.index + 1)">
                                    <option v-for="(option,index) in options" :key="index" :value="option.value">{{ option.text }}</option>
                                </select>
                            </template>
                        </b-table>

                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
export default {
    name:'ChannelsComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect'),
    },data (){
        return{
            singleBusinessDatas:{
                businessName:[],
                channelName:'',
                channelURL:'',
                channelIP:'',
                technicalIntegration:false
            },

            businessNameOptions: ['PAYINC GROUP LTD','PAYINC CA LLC','PAYINC US LLC','PAYINC GHANA LTD'],

            text: ' Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry',
            businessDatas:[
                {
                    id:'business_0',
                    businessName:'PAYINC GROUP LTD',
                    tradebuttonText:'Add Trade Name',
                    isActive:true,
                    tradeLists:[
                        {
                            name: 'PayAngel',
                            url: 'www.payangel.com',
                            isActive: false
                        },
                        {
                            name: 'PayAngel US',
                            url: 'www.payangel.com',
                            isActive: true
                        },
                        {
                            name: 'StandApp',
                            url: 'www.standapp.com',
                            isActive: false
                        },
                        {
                            name: 'Angel Communities',
                            url: '',
                            isActive: false
                        }
                    ]
                },
                {
                    id:'business_1',
                    businessName:'PAYINC CA LLC',
                    tradebuttonText:'Add Trade Name',
                    isActive:false,
                    tradeLists:[
                        {
                            name: 'StandApp',
                            url: 'www.standapp.com',
                            isActive: false
                        },
                        {
                            name: 'PayAngel',
                            url: 'www.payangel.com',
                            isActive: false
                        },
                        {
                            name: 'Angel Communities',
                            url: 'www.payangel.com',
                            isActive: false
                        }
                        
                    ]
                },
                {
                    id:'business_2',
                    businessName:'PAYINC US LLC',
                    tradebuttonText:'Add Trade Name',
                    isActive:false,
                    tradeLists:[
                        {
                            name: 'Angel Communities',
                            url: 'www.payangel.com',
                            isActive: false
                        },
                        {
                            name: 'StandApp',
                            url: 'www.standapp.com',
                            isActive: false
                        },
                        {
                            name: 'PayAngel',
                            url: 'www.payangel.com',
                            isActive: false
                        }
                    ]
                },
                {
                    id:'business_3',
                    businessName:'PAYINC GHANA LTD',
                    tradebuttonText:'Add Trade Name',
                    isActive:false,
                    tradeLists:[
                        {
                            name: 'StandApp',
                            url: 'www.standapp.com',
                            isActive: false
                        },
                        {
                            name: 'PayAngel',
                            url: 'www.payangel.com',
                            isActive: false
                        }
                    ]
                }
            ],

            tradInputs:{
                tradeName:''
            },

            urlFormData:{
                urlValue:'www.payangel.com',
                urlActiveIndex:0,
                urlPrefixsTitle:'https://',
                ipAddress:'192.168.1.1',
                successURL:'www.payangel.com',
                successUrlActiveIndex:0,
                successUrlPrefixsTitle:'https://',
                errorURL:'www.payangel.com',
                errorUrlActiveIndex:0,
                errorUrlPrefixsTitle:'https://',
            },

            urlSelectList:[
                {
                    name:'https://'
                },
                {
                    name:'http://'
                }
            ],

            options: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ],

            items:[
                {url: 'https://www.payangel.com', extension:'https://www.payangel.com', events:3, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.wrraptheme.com', extension:'https://www.wrraptheme.com', events:1, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.thememakker.com', extension:'https://www.thememakker.com', events:5, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.wrraptheme.com', extension:'https://www.wrraptheme.com', events:4, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.thememakker.com', extension:'https://www.thememakker.com', events:2, dropdawn:{selected:'cancel',class:'btn-outline-danger'}},
            ],
           
            fields: [
                { key: 'url', label: 'Channel URL',sortable: true,thClass:'th_sortfix'},
                { key: 'extension', label: 'Webhook Extension',sortable: true,thClass:'th_sortfix'},
                { key: 'events', label: 'No. of events',sortable: true,thClass:'th_sortfix'},
                { key: 'dropdawn', label: 'Status'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            webhooks:{
                url:'',
                status:'',
                extension:'',
                events: [],
                isAllChecked:false,
            },

            statusOptions: [
                'accept',
                'cancel',
            ],
            urlOptions:['https://www.payangel.com','https://www.thememakker.com','https://www.wrraptheme.com'],

            //All curancy dropdawn option
            events_options: [
                { id:0,name: 'Card verification declined',isChecked: false},
                { id:1,name: 'Card verified' , isChecked: false},
                { id:2,name: 'Dispute canceled', isChecked: false},
                { id:3,name: 'Dispute evidence required',isChecked: false},
            ],
            APIkeysInfo:{
                secretKey:'************************',
                publickKey:'****************************'
            },
            
            channels:{
                currentURL:['https://']
            },

            channelURL_option:['https://','http://'],
        }
    },methods: {
        //single business submit form data
        channelSubmitForm(){
            this.$bvModal.hide('add_channel');
        },

        //trade submit form data
        tradSubmitForm(index){
            if(this.tradInputs.tradeName){
                this.$bvModal.hide('trade_model'+index);
            }
        },

        urlSelectedOptions(menuIndex,type){
            if(type == 'url'){
                this.urlFormData.urlActiveIndex = menuIndex;
                this.urlFormData.urlPrefixsTitle = this.urlSelectList[menuIndex].name;
            }else if(type == 'successUrl'){
                this.urlFormData.successUrlActiveIndex = menuIndex;
                this.urlFormData.successUrlPrefixsTitle = this.urlSelectList[menuIndex].name;
            }else{
                this.urlFormData.errorUrlActiveIndex = menuIndex;
                this.urlFormData.errorUrlPrefixsTitle = this.urlSelectList[menuIndex].name;
            }
            
        },
        
        urlFormSubmit(){
            console.log("submit");
        },

        //status change
        activeStatus(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.dropdawn.class = classVar
                }
            });
        },

        //add webhooks form submit
        webHooksForm(){
            this.$bvModal.hide('add_webhooks');
        },

        //copy secret key
        copyKey(keyType){
            // creating input of html
            var inputofKey = document.createElement("input");

            //adding span tag text to input 
            inputofKey.value = keyType == 'secretKey' ? 'sk_test_tR3PYbcVNZZ796tH88S4VQ2u' : 'pt_testing_tR3PYbcVNVV796tH89S4VQ8u';
            document.body.appendChild(inputofKey);
            inputofKey.select();
            document.execCommand("Copy");

            // removing input after copy
            inputofKey.remove();
        },

        //
        onSelectURL (selectedAction) {
            this.webhooks.extension = selectedAction
        },

        //Events dropdawn checkbox checked(one or more)
        selectAllChanged() {
            if (this.isAllSelected) {
                this.webhooks.isAllChecked = false;
                this.webhooks.events = [];
                this.events_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.webhooks.isAllChecked = true;
                this.webhooks.events = this.events_options.slice();
                this.events_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        //All Events dropdawn selection message is no of Events selected
        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.webhooks.events[0].name
            }else if(count == this.events_options.length){
                this.webhooks.isAllChecked = true;
                return count + ' Events Selected.'
              
            }else {
                this.webhooks.isAllChecked = false;
                return  count + ' Events Selected.'
            }
        },

        //all Events dropdawn checkbox checked
        isCheckedInput (actionName) {
            this.events_options[actionName.id].isChecked = !this.events_options[actionName.id].isChecked
        },
    },mounted(){
        this.totalRows = this.items.length
    },computed: {
        //check how many Events dropdawn checkbox checked
        isAllSelected: function() {
            return  (this.webhooks.events.length === this.events_options.length)
        }
    }
}

</script>